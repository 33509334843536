import classes from "./ProjectForm.module.scss";
import Form from "forms/components/form";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";
import { ApiDataType, FormDataType } from "types";
import { useSearchParams } from "react-router-dom";
import { splitDate } from "helpers/dates";
import { useState } from "react";

interface ProjectFormProps {
  isSingleColumn: boolean;
  updateProducts: (searchQuery: string | null, ordering: string | null) => void;
}

const ProjectForm = ({ isSingleColumn, updateProducts }: ProjectFormProps) => {
  const [resDataAfterSubmit, setResDataAfterSubmit] =
    useState<ApiDataType | null>(null);

  const { apiPost } = useApi();
  const [searchParams] = useSearchParams();

  const onSubmit = async (form: FormDataType, reset?: () => void) => {
    const formData = new FormData();
    
    if (form?.icon instanceof FileList) {
      formData.append("icon", form.icon[0]);
      delete form.icon;
    }
    if (form?.date_start instanceof Date)
      form.date_start = splitDate(form.date_start.toLocaleDateString());
    if (form?.date_end instanceof Date)
      form.date_end = splitDate(form.date_end.toLocaleDateString());
    if (Array.isArray(form.statuses)) {
      form.statuses.map((status) => formData.append("statuses", `${status}`));
      delete form.statuses;
    }

    //TODO прелоадер во время отправки или дизейблед пока нет ответа от сервера
    const res = await apiPost(endpoints.projects, formData, undefined, true, {
      "Content-Type": "multipart/form-data",
    });
    if (res.data) {
      const search = searchParams.get("search");
      const ordering = searchParams.get("ordering");
      updateProducts(search, ordering);
      if (reset) reset();
    }
    setResDataAfterSubmit(res);
  };

  const skeleton = {
    style: {
      width: "100%",
      height: "100%",
      minHeight: "182px",
    },
  };

  return (
    <div
      className={
        isSingleColumn
          ? [classes.container, classes["container--single"]].join(" ")
          : classes.container
      }
    >
      <Form
        apiUrl={endpoints.projectsCreate}
        btnText="Добавить"
        classValueForm={classes.form}
        formId="createProjectForm"
        onSubmit={onSubmit}
        resDataAfterSubmit={resDataAfterSubmit}
        skeleton={skeleton}
      />
    </div>
  );
};

export default ProjectForm;
