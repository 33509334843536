import { useEffect, useState } from "react";
import classes from "./ManagementList.module.scss";
import EditTask from "components/ui/icons/EditTask";
import { useApi } from "hooks/useApi/useApi";
import { useSearchParams } from "react-router-dom";
import EmptyImage from "assets/img/empty-image.png";
import { ManagementEmployeeType } from "types";
import Skeleton from "react-loading-skeleton";

const ManagementList = () => {
  const [data, setData] = useState<ManagementEmployeeType[] | null>(null);
  const [searchParams] = useSearchParams();
  const { apiGet } = useApi();

  useEffect(() => {
    const getManagementList = async () => {
      if (searchParams.get("position")) {
        searchParams.set("role", searchParams?.get("position") || "");
        searchParams.delete("position");
      }
      const res = await apiGet(
        `${process.env.REACT_APP_API_URL}/api/employee/employees`,
        searchParams,
      );

      res.data?.data && setData(res.data.data);
    };
    getManagementList();
  }, [searchParams]);

  return (
    <div className={classes.container}>
      {data ? (
        data.map((item) => (
          <div key={item.id} className={classes.row}>
            <div className={classes.name}>
              <div className={classes.image}>
                <img src={item.photo ? item.photo : EmptyImage} alt="avatar" />
              </div>
              {item.first_name} {item.last_name}
            </div>
            <div className={classes.position}>{item.position}</div>
            <div className={classes.competence}>{"middle"}</div>
            <div className={classes.department}>
              {"ter"} {item.department}
            </div>
            <div className={classes.hours}>Часы работы: {"08:00 - 17:00"}</div>
            <EditTask clazz={classes.edit} />
          </div>
        ))
      ) : (
        <Skeleton
          count={6}
          style={{
            height: "80px",
            display: "block",
            width: "100%",
          }}
        />
      )}
    </div>
  );
};

export default ManagementList;
