import { useLocation, Link } from "react-router-dom";
import classes from "./Menu.module.scss";
import User from "components/ui/icons/User";
import Education from "components/ui/icons/Education";
import Employment from "components/ui/icons/Employment";
import Projects from "components/ui/icons/Projects";
import Reports from "components/ui/icons/Reports";
import HR from "components/ui/icons/HR";
import { UserRoleContext } from "context/RoleContext";
import { useContext } from "react";

interface MenuProps {
  openSidebar: boolean;
}

const Menu = ({ openSidebar }: MenuProps) => {
  const { userRoleContext } = useContext(UserRoleContext);
  const location = useLocation();

  const navClassName = openSidebar
    ? classes["long-menu"]
    : classes["short-menu"];

  const classNameProfileLink = `${classes.link} ${
    location.pathname === "/" ? classes["link--active"] : ""
  }`;

  const classNameEducationLink = `${classes.link} ${
    location.pathname.includes("/planning") ? classes["link--active"] : ""
  }`;

  const classNameLoggingLink = `${classes.link} ${
    location.pathname.includes("/logging") ? classes["link--active"] : ""
  }`;

  const classNameProjectsLink = `${classes.link} ${
    location.pathname.includes("/projects") ? classes["link--active"] : ""
  }`;

  const classNameReportsLink = `${classes.link} ${
    location.pathname.includes("/reports") ? classes["link--active"] : ""
  }`;

  const classNameManagementLink = `${classes.link} ${
    location.pathname.includes("/management") ? classes["link--active"] : ""
  }`;

  return (
    <nav className={navClassName}>
      <ul className={classes.container}>
        <li>
          <Link to={"/"} title="Профиль" className={classNameProfileLink}>
            <User clazz={classes.icon} />
            <span className={classes.title}>Профиль</span>
          </Link>
        </li>
        <li>
          <Link
            to={"/planning"}
            title="Планирование"
            className={classNameEducationLink}
          >
            <Education clazz={classes.icon} />
            <span className={classes.title}>Планирование</span>
          </Link>
        </li>
        <li>
          <Link
            to={"/logging"}
            title="Логирование времени"
            className={classNameLoggingLink}
          >
            <Employment clazz={classes.icon} />
            <span className={classes.title}>Логирование времени</span>
          </Link>
        </li>
        <li>
          <Link
            to={"/projects"}
            title="Проекты"
            className={classNameProjectsLink}
          >
            <Projects clazz={classes.icon} />
            <span className={classes.title}>Проекты</span>
          </Link>
        </li>
        <li>
          <Link to={"/reports"} title="Отчеты" className={classNameReportsLink}>
            <Reports clazz={classes.icon} />
            <span className={classes.title}>Отчеты</span>
          </Link>
        </li>
        {userRoleContext.data?.can_set_project_manager && <li>
          <Link to={"/management"} title="HR кабинет" className={classNameManagementLink}>
            <HR clazz={classes.icon} />
            <span className={classes.title}>HR кабинет</span>
          </Link>
        </li>}
      </ul>
    </nav>
  );
};

export default Menu;
