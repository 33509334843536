import classes from "./Button.module.scss";
import Success from "../icons/Success";

interface ButtonProps {
  clazz?: string;
  children: string | JSX.Element;
  type?: "button" | "submit" | "reset" | undefined;
  success?: boolean;
  icon?: JSX.Element;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Button = ({
  clazz,
  children,
  type = "button",
  success = false,
  icon,
  disabled = false,
  onClick = undefined,
}: ButtonProps) => {
  return (
    <button
      type={type}
      disabled={disabled}
      className={`${classes.button} ${
        success ? classes.buttonSuccess : ""
      } ${clazz ? clazz : ""}`}
      onClick={(e) => (onClick ? onClick(e) : undefined)}
    >
      {icon || success && <Success />}
      <span className={classes.text}>{children}</span>
    </button>
  );
};

export default Button;
