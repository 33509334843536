import classes from "./ManagementHeader.module.scss";

type TManagementHeaderProps = {
  setTab: React.Dispatch<React.SetStateAction<string>>;
};

const ManagementHeader = ({ setTab }: TManagementHeaderProps) => {
  return (
    <div className={classes.tabs}>
      <input
        type="radio"
        name="tabs"
        defaultChecked
        onChange={() => setTab("skills")}
        id="skills"
        className={classes.radio}
      />
      <label className={classes.tab} htmlFor="skills">
        Управление талантами
      </label>
      <input
        type="radio"
        name="tabs"
        onChange={() => setTab("analytics")}
        id="analytics"
        className={classes.radio}
      />
      <label className={classes.tab} htmlFor="analytics">
        HR-аналитика
      </label>
      <input
        type="radio"
        name="tabs"
        onChange={() => setTab("archive")}
        id="archive"
        className={classes.radio}
      />
      <label className={classes.tab} htmlFor="archive">
        Архив соискателей
      </label>
      <input
        type="radio"
        name="tabs"
        onChange={() => setTab("sach")}
        id="sach"
        className={classes.radio}
      />
      <label className={classes.tab} htmlFor="sach">
        САЧ
      </label>
    </div>
  );
};

export default ManagementHeader;
