import classes from "./ManagementSkills.module.scss";
import { useEffect, useState } from "react";
import SelectWrapper from "forms/components/simpleWidgets/select-input/SelectWrapper";
import Search from "components/common/search/Search";
import { useSearchParams } from "react-router-dom";
import { useDebounce } from "hooks/useDebounce/useDebounce";
import { createParams } from "helpers/createParams";
import Button from "components/ui/button/Button";
import Plus from "components/ui/icons/Plus";
import ManagementList from "./ManagementList/ManagementList";

const ManagementSkills = () => {
  const [resetSelect, setResetSelect] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  const _search = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      setSearchParams(
        createParams(searchParams, { search: e.target.value.trim() }),
      );
    } else {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  };
  const search = useDebounce(_search, 500);

  useEffect(() => {
    setResetSelect(true);
  }, [searchParams]);

  const onChangeDepartments = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, { department: value.toString() }),
      );
    }
  };

  const onChangePositions = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, { position: value.toString() }),
      );
    }
  };

  const onChangeOffices = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(createParams(searchParams, { office: value.toString() }));
    }
  };

  const onChangeCompetences = (value: string | number | Date | string[]) => {
    if (value !== undefined) {
      setSearchParams(
        createParams(searchParams, {
          competence: value.toString(),
        }),
      );
    }
  };

  useEffect(() => {
    if (resetSelect) setResetSelect(false);
  });

  const optionStyle = { fontSize: "1rem", fontWeight: 500, lineHeight: "20px" };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.selects}>
          <Search
            onInput={search}
            placeholder="Поиск"
            clazz={classes.search}
            initialValue={searchParams.get("search") || ""}
            autoFocus={searchParams.get("search") ? true : false}
          />
          <SelectWrapper
            reset={resetSelect}
            name="positions"
            placeholder="Должность"
            value={searchParams.get("position")}
            choices={[
              { name: "Все должности", label: "all", value: "all" },
              {
                name: "Топ менеджер",
                label: "top_manager",
                value: "top_manager",
              },
              {
                name: "Операционный менеджер",
                label: "op_manager",
                value: "op_manager",
              },
              {
                name: "Проектный менеджер",
                label: "project_manager",
                value: "project_manager",
              },
              { name: "Разработчик", label: "developer", value: "developer" },
            ]}
            onChange={onChangePositions}
            searchable={true}
            optionStyle={optionStyle}
          />
          <SelectWrapper
            reset={resetSelect}
            name="competences"
            placeholder="Сотрудники"
            value={searchParams.get("competence")}
            choices={[{ name: "Все сотрудники", label: "all", value: "all" }]}
            choices_url="api/employee/employees/choices/"
            onChange={onChangeCompetences}
            searchable={true}
            optionStyle={optionStyle}
          />
          <SelectWrapper
            reset={resetSelect}
            name="departments"
            placeholder="Подразделение"
            value={searchParams.get("department")}
            choices={[
              { name: "Все подразделения", label: "all", value: "all" },
              { name: "Белгород", label: "bel", value: "2" },
            ]}
            onChange={onChangeDepartments}
            searchable={true}
            optionStyle={optionStyle}
          />

          <SelectWrapper
            reset={resetSelect}
            name="offices"
            placeholder="Офис"
            value={searchParams.get("office")}
            choices={[{ name: "Все офисы", label: "all", value: "all" }]}
            onChange={onChangeOffices}
            searchable={true}
            optionStyle={optionStyle}
          />
        </div>
        <div className={classes.buttons}>
          <Button type="button" clazz={classes.button}>
            Настройка квалификации
          </Button>
          <Button type="button" clazz={classes.button}>
            <>
              <Plus clazz={classes.icon} />
              Добавить сотрудника
            </>
          </Button>
        </div>
      </div>
      <ManagementList />
    </div>
  );
};

export default ManagementSkills;
