import DateInput from "forms/components/simpleWidgets/date-input/DateInput";
import classes from "./TableDesktop.module.scss";
import SelectWrapper from "forms/components/simpleWidgets/select-input/SelectWrapper";
import TimeInput from "forms/components/simpleWidgets/time-input/TimeInput";
import TextInput from "forms/components/simpleWidgets/text-input/TextInput";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { PatchDataType } from "types";
import { UserRoleContext } from "context/RoleContext";
import { dateToRFC } from "helpers/dates";
import { useApi } from "hooks/useApi/useApi";
import { endpoints } from "API/endpoints";

type TTableDesktopProps = {
  setUpdateTable: Dispatch<SetStateAction<boolean>>;
  setIsFormOpen: Dispatch<SetStateAction<boolean>>;
};

const TableDesktop = ({
  setUpdateTable,
  setIsFormOpen,
}: TTableDesktopProps) => {
  const [formInputs, setFormInputs] = useState<PatchDataType>({
    task: null,
    project: null,
    date: null,
    action_type: null,
    spent_time_min: null,
  });

  const { userRoleContext } = useContext(UserRoleContext);
  const { apiPost } = useApi();

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    const loggingData = Object.assign({}, formInputs, {
      date: formInputs.date ? formInputs.date : dateToRFC(new Date()),
    });

    const res = await apiPost(
      endpoints.addUserLogging,
      loggingData,
      undefined,
      true,
    );

    if (res.data) {
      setUpdateTable(true);
      setIsFormOpen((prev) => !prev);
    }
  };

  return (
    <form id="LoggingForm" className={classes.form} onSubmit={onSubmit}>
      <TextInput
        name="textInputLogging"
        value={""}
        placeholder={"Название задачи"}
        onChange={(value) => {
          setFormInputs({
            ...formInputs,
            task: typeof value === "string" ? value : null,
          });
        }}
      />
      <SelectWrapper
        name="selectLogging"
        onChange={(value) => {
          setFormInputs({
            ...formInputs,
            project: typeof value === "number" ? value : null,
          });
        }}
        placeholder={"Выбрать проект"}
        choices={undefined}
        choices_url="/api/project/project_time_tracking/create_form/choices/project/"
      />
      <DateInput
        name="dateLogging"
        onChange={(value) => {
          setFormInputs({
            ...formInputs,
            date: value instanceof Date ? dateToRFC(value) : null,
          });
        }}
        value={null}
        placeholder={""}
        disabled={
          userRoleContext.data.can_choose_date_project_time_tracking
            ? false
            : true
        }
      />
      <SelectWrapper
        name="selectLogging"
        onChange={(value) => {
          setFormInputs({
            ...formInputs,
            action_type: typeof value === "string" ? value : null,
          });
        }}
        placeholder={"Тип работы"}
        choices_url={"api/project/action_type"}
      />
      <TimeInput
        name="timeInputLogging"
        value=""
        onChange={(value) => {
          setFormInputs({
            ...formInputs,
            spent_time_min: typeof value === "string" ? value : null,
          });
        }}
      />
      <SelectWrapper
        name="taskStatus"
        onChange={(value) => {
          setFormInputs({
            ...formInputs,
            task_status: typeof value === ("number" || "string") ? value : null,
          });
        }}
        choices_url={`api/project/project_status_list${
          formInputs.project ? `?project=${formInputs.project}` : ""
        }`}
        placeholder={"Цель задачи"}
        autoFocus={true}
      />
      <button type={"submit"} form={"LoggingForm"} className={classes.formBtn}>
        {" "}
      </button>
    </form>
  );
};

export default TableDesktop;
