interface IconProps {
  clazz?: string;
}

const Plus = ({ clazz }: IconProps) => {
  return (
    <svg
      className={clazz}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 5.25C12.75 4.83579 12.4142 4.5 12 4.5C11.5858 4.5 11.25 4.83579 11.25 5.25V11.25H5.25C4.83579 11.25 4.5 11.5858 4.5 12C4.5 12.4142 4.83579 12.75 5.25 12.75H11.25V18.75C11.25 19.1642 11.5858 19.5 12 19.5C12.4142 19.5 12.75 19.1642 12.75 18.75V12.75H18.75C19.1642 12.75 19.5 12.4142 19.5 12C19.5 11.5858 19.1642 11.25 18.75 11.25H12.75V5.25Z"
        fill="#00C2FA"
      />
    </svg>
  );
};

export default Plus;
